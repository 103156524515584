import React, { FunctionComponent, ReactNode } from 'react';
import Header from '../Header';
import Navigation from '../Navigation';

export interface Props {
  children: ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }: Props) => (
  <>
    <Header>
      <Navigation />
    </Header>
    {children}
  </>
);

export default Layout;
