import React, { FunctionComponent } from 'react';

const VenueForm: FunctionComponent = () => (
  <form className="form">
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="contact_name"
        placeholder="Contact name"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="email"
        name="contact_email"
        placeholder="Contact email address"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="tel"
        name="contact_phone"
        placeholder="Contact phone number"
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="venue_name"
        placeholder="Venue name"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="venue_address"
        placeholder="Venue address"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="url"
        name="venue_website"
        placeholder="Venue website"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="venue_capacity"
        placeholder="Venue capacity"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="venue_style"
        placeholder="Venue style"
      />
    </div>
    <div className="form-group">
      <button className="button light" type="submit">
        Submit
      </button>
    </div>
  </form>
);

export default VenueForm;
