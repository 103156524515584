import React, { FunctionComponent } from 'react';

const Register: FunctionComponent = () => (
  <>
    <section className="section">
      <h2 className="h2">Register for the 2022 Lubbock Venue Crawl</h2>
      <p>Join us on February 99, 2022 for the Lubbock Venue Crawl ...</p>
      <form>Register form</form>
    </section>
  </>
);

export default Register;
