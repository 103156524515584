import React, { FunctionComponent } from 'react';

const VendorForm: FunctionComponent = () => (
  <form className="form">
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="contact_name"
        placeholder="Contact name"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="email"
        name="contact_email"
        placeholder="Contact email address"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="business_name"
        placeholder="Business name"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="business_category"
        placeholder="Business category"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="links"
        placeholder="Links (business website & social media)"
        required
      />
    </div>
    <div className="form-group">
      <input
        className="text-input"
        type="text"
        name="date_established"
        placeholder="Date established"
        required
      />
    </div>
    <div className="form-group">
      <textarea
        className="textarea"
        name="client_description"
        placeholder="Please describe your ideal client"
      />
    </div>
    <div className="form-group">
      <textarea
        className="textarea"
        name="preferred_vendor_at"
        placeholder="Are you a preferred vendor at any venues? If so, please list them here."
      />
    </div>
    <div className="form-group">
      <button className="button light" type="submit">
        Submit
      </button>
    </div>
  </form>
);

export default VendorForm;
