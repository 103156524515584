import React, { FunctionComponent } from 'react';
import WithWallpaper from '../WithWallpaper';
import SubscribeForm from '../SubscribeForm';

const content = {
  hero: {
    bgImageUrl:
      'https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    heading: 'Join Us',
    copy: 'Planning your wedding? Join our email list to be the first to know when registration opens.',
  },
};

const Landing: FunctionComponent = () => (
  <>
    <WithWallpaper image={content.hero.bgImageUrl}>
      <div className="landing">
        <div className="content">
          <h1 className="heading">{content.hero.heading}</h1>
          <div className="body">{content.hero.copy}</div>
          <SubscribeForm />
        </div>
      </div>
    </WithWallpaper>
  </>
);

export default Landing;
