import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

const content = {
  points: [
    {
      heading: 'Connect',
      body: 'The Lubbock Venue Crawl provides a unique opportunity for guests, venues, and vendors to connect with one another ...',
    },
    {
      heading: 'Grow',
      body: 'The Lubbock Venue Crawl is a growing community of wedding professionals ...',
    },
    {
      heading: 'Host',
      body: 'As a venue, it can be callenging to ... Host guests in your space as part of The Lubbock Venue Crawl ...',
    },
    {
      heading: 'Exhibit',
      body: 'Wedding professionals, share your expertise ...',
    },
  ],
};

const Exhibit: FunctionComponent = () => (
  <>
    <section className="section">
      <h2 className="h2">Apply for the 2022 Lubbock Venue Crawl</h2>
      <p>February 99, 2022</p>
      <p>Lubbock, TX</p>
      <div className="button-group">
        <Link className="button light" to="/venue-app">
          Venue Application
        </Link>
        <Link className="button light" to="/exhibitor-app">
          Exhibitor Application
        </Link>
      </div>
    </section>
    <section className="section">
      {content.points.map(({ heading, body }) => (
        <div key={heading}>
          <h2 className="h2">{heading}</h2>
          <p>{body}</p>
        </div>
      ))}
    </section>
  </>
);

export default Exhibit;
