import React, { FunctionComponent } from 'react';
import VenueAppForm from '../VenueAppForm';

const VenueApp: FunctionComponent = () => (
  <>
    <section className="section">
      <div>
        <h1 className="h2">Venue Application</h1>
        <p>Are you a wedding venue ...</p>
        <VenueAppForm />
      </div>
    </section>
  </>
);

export default VenueApp;
