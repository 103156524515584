import React, { FunctionComponent, ReactNode } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

export interface Props {
  children: ReactNode;
}

const Header: FunctionComponent<Props> = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { siteName },
    },
  } = data;

  return (
    <div className="header">
      <div className="brand">
        <Link to="/" className="icon">
          {siteName}
        </Link>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default Header;
