import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

const Navigation: FunctionComponent = () => {
  const items = [
    { name: 'The Event', slug: '/event' },
    { name: 'Register', slug: '/register' },
    { name: 'Exhibit', slug: '/exhibit' },
    { name: 'About', slug: '/about' },
    { name: 'Contact', slug: '/contact' },
  ];

  return (
    <nav className="nav light">
      {items.map(({ name, slug }) => (
        <div className="item" key={name}>
          <Link className="link" to={slug}>
            {name}
          </Link>
        </div>
      ))}
    </nav>
  );
};

export default Navigation;
