import React, { FunctionComponent } from 'react';

const NotFound: FunctionComponent = () => (
  <div className="not-found">
    <div className="message">
      <h1 className="heading">Page not found</h1>
    </div>
  </div>
);

export default NotFound;
