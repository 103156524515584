import React, { FunctionComponent } from 'react';
import ExhibitorAppForm from '../ExhibitorAppForm';

const ExhibitorApp: FunctionComponent = () => (
  <>
    <section className="section">
      <div>
        <h1 className="h2">Exhibitor Application</h1>
        <p>Are you a wedding professional ...</p>
        <ExhibitorAppForm />
      </div>
    </section>
  </>
);

export default ExhibitorApp;
