import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { useFormik } from 'formik';
import type { FormikErrors } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import isValidEmail from '../../utils/isValidEmail';

interface FormValues {
  email: string;
}

const SubscribeForm: FunctionComponent = () => {
  const [formSubmit, setFormSubmit] = useState({ result: '', msg: '' });

  const initialValues: FormValues = {
    email: '',
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    const result = await addToMailchimp(values.email);
    setFormSubmit(result);
  };

  const formik = useFormik({ initialValues, validate, onSubmit });

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      {formSubmit.result === 'success' ? (
        <div className="form-group">
          <div
            className="success-message"
            dangerouslySetInnerHTML={{ __html: formSubmit.msg }}
          />
        </div>
      ) : (
        <>
          <div className="form-group">
            {formik.errors.email && formik.touched.email && (
              <div className="field-error">{formik.errors.email}</div>
            )}
            <input
              className="text-input"
              placeholder="Email address"
              {...formik.getFieldProps('email')}
            />
          </div>
          <div className="form-group">
            <button
              className="button dark"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Subscribe
            </button>
          </div>
          <div className="form-group">
            {formSubmit.result === 'error' && (
              <div
                className="form-error"
                dangerouslySetInnerHTML={{ __html: formSubmit.msg }}
              />
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default SubscribeForm;
