import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

const Event: FunctionComponent = () => (
  <>
    <section className="section">
      <h2 className="h2">Event info</h2>
      <p>February 99, 2022</p>
      <p>Lubbock, TX</p>
      <p>Ticket prices</p>
      <div>
        <Link className="button dark wide" to="/register">
          Register
        </Link>
      </div>
    </section>
    <section className="section">
      <h2 className="h2">Venue list</h2>
      <p>List of venues</p>
    </section>
    <section className="section">
      <h2 className="h2">Exhibitor list</h2>
      <p>List of exhibitors</p>
    </section>
    <section className="section">
      <div>
        <h2 className="h2">Join us at the 2022 venue crawl</h2>
        <p>Register to ...</p>
        <Link className="button dark wide" to="/register">
          Register
        </Link>
      </div>
    </section>
  </>
);

export default Event;
