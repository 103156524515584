import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  image: string;
  children: ReactNode;
}

const WithWallpaper: FunctionComponent<Props> = ({
  image,
  children,
}: Props) => (
  <div className="with-wallpaper">
    {children}
    <div className="image-container">
      <img className="image" src={image} alt="" />
    </div>
  </div>
);

export default WithWallpaper;
