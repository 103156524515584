import React, { FunctionComponent } from 'react';
import ContactForm from '../ContactForm';

const content = {
  form: {
    heading: 'Contact Us',
    copy: "Questions about the event? Reach out on the form below. We can't wait to hear from you.",
  },
};

const Contact: FunctionComponent = () => (
  <>
    <section className="section">
      <div>
        <h1 className="h2">{content.form.heading}</h1>
        <p>{content.form.copy}</p>
        <ContactForm />
      </div>
    </section>
  </>
);

export default Contact;
