import React, { FunctionComponent } from 'react';

const ContactForm: FunctionComponent = () => (
  <form className="form">
    <div className="form-group">
      <input
        className="text-input"
        type="email"
        name="email"
        placeholder="Email address"
      />
    </div>
    <div className="form-group">
      <textarea className="textarea" name="message" placeholder="Message" />
    </div>
    <div className="form-group">
      <button className="button light" type="submit">
        Submit
      </button>
    </div>
  </form>
);

export default ContactForm;
