import React, { FunctionComponent } from 'react';

const content = {
  event: {
    heading: 'What is the Lubbock Venue Crawl?',
    copy: '<p>Engaged couples in Lubbock struggle to find the best professionals and planning resources for their weddings. This causes stress and leads to compromising their wedding vision. We have created a fun and engaging afternoon for couples to tour the best Lubbock venues and connect with quality wedding professionals, making wedding planning an enjoyable experience.</p><p>To learn more about the Lubbock Venue Crawl, visit the <a href="/event">event page</a>.</p>',
  },
  founders: {
    heading: 'About the founders',
    copy: '<p>We are wedding professionals in Lubbock, TX.</p>',
  },
  learn: {
    heading: 'Learn more',
    copy: '<p>To learn more about the Lubbock Venue Crawl, visit the <a href="/event">event page</a>.</p>',
  },
};

const About: FunctionComponent = () => (
  <>
    <section className="section">
      <h2 className="h2">{content.event.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: content.event.copy }} />
    </section>
    <section className="section">
      <h2 className="h2">{content.founders.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: content.founders.copy }} />
    </section>
    <section className="section">
      <h2 className="h2">{content.learn.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: content.learn.copy }} />
    </section>
  </>
);

export default About;
